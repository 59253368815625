.dropdown-item:hover {
  cursor: pointer;
}
.dropdown-item-disabled:hover {
  cursor: not-allowed;
}

.dropdown-item label:hover {
  cursor: pointer;
}
.dropdown-item-disabled label:hover {
  cursor: not-allowed;
}

/* Dark-theme */
.dark-theme .dropdown-item label:hover {
  color: var(--white-pure);
}
.dark-theme .dropdown-item-disabled label {
  color: var(--black70) !important;
}
.dark-theme .column label:hover {
  color: var(--white-pure);
}
