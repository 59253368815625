.create-job-container {
  background: var(--white);
  transition: all 0.5s linear, background 0s linear;
}

.dark-theme .page-container {
  background: var(--dark-page-background) !important;
}
.dark-theme .create-job-container {
  background: var(--dark-input-background) !important;
}
.dark-theme .advertise-card {
  background: var(--dark-page-background);
  border-color: var(--black130);
  box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1);
}

#chart-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.dark-theme .apply-button {
  background: var(--dark-input-background) !important;
}
