/* SELECT BUTTON */
.btn-select {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;

  border-color: var(--primary_default) !important;
  color: var(--primary_default) !important;
  font-weight: 600 !important;
  width: 105px;
}
.btn-select:hover {
  color: var(--white_pure) !important;
  font-weight: 400 !important;
}
.dark-theme .btn-select {
  color: var(--black10) !important;
}

/* ADD PROMOTION BUTTON */
.btn-add-promotion {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  color: var(--primary_default) !important;
}



