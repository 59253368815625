.otp-title {
  margin-bottom:16px;
}
.otp-subtitle {
  margin-bottom:0
}
.otp-input-wrapper {
  padding-top: 44px;
  padding-bottom:24px;
  @media(min-width:992px) {
    padding-top: 52px;
  }
}
.otp-input-wrapper input{
  border:1px solid;
  border-color: #4A4A69;
  border-radius: 2px;
  height: 48px !important;
  width: 48px !important;

  font-family: Poppins;
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;

  @media (min-width:768px) {
    height: 48px !important;
    width: 48px !important;
  }
}
.otp-input-wrapper input:focus-visible {
  border-color: var(--primary-default);
  outline:var(--primary-default);
}
.otp-input-wrapper div{
  gap: 8px;
  @media(min-width:992px) {
    gap:12px;
  }
}